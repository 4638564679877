<template>
  <div class="stepsdemo-content">
    <Toast position="top-right" />
    <div class="p-grid">
      <div class="p-col-12">
        <h6 v-if="isError" style="color: red">Tous les champs modifiés doivent être remplis.</h6>
        <br />
        <div class="card">
          <h5>Mon Profil</h5>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
              <label for="name">Nom</label> <span class="p-text-bold text-red">*</span>
              <InputText
                v-model="nom"
                id="name"
                type="text"
                :class="{ 'p-invalid': !nom && submitted }"
                maxlength="15"
              />
              <h6 v-if="!nom && submitted" style="color: red">Veuillez renseigner le nom.</h6>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="surname">Prénom</label> <span class="p-text-bold text-red">*</span>
              <InputText
                v-model="prenom"
                id="surname"
                type="text"
                :class="{ 'p-invalid': !prenom && submitted }"
                maxlength="15"
              />
              <h6 v-if="!prenom && submitted" style="color: red">Veuillez renseigner le prénom.</h6>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="mobile">Téléphone 1</label> <span class="p-text-bold text-red">*</span>
              <InputText
                class="no-arrow"
                v-model="mobile"
                id="mobile"
                type="number"
                :class="{ 'p-invalid': !mobile && submitted }"
              />
              <h6 v-if="!mobile && submitted" style="color: red">Veuillez renseigner le téléphone.</h6>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="email">E-mail</label> <span class="p-text-bold text-red">*</span>
              <InputText
                :disabled="true"
                v-model="email"
                id="email"
                type="text"
                :class="{ 'p-invalid': !email && email.indexOf('@') == -1 && email.indexOf('.') == -1 && submitted }"
                maxlength="40"
              />
              <h6 v-if="!email && submitted" style="color: red">Veuillez renseigner l'e-mail.</h6>
              <h6
                v-if="
                  (email && submitted && email.indexOf('@') == -1) || (email && submitted && email.indexOf('.') == -1)
                "
                style="color: red"
              >
                Veuillez respecter le format du champ « E-mail de l’entreprise »
              </h6>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="fonction">Fonction dans l'entreprise</label> <span class="p-text-bold text-red">*</span>
              <InputText
                v-model="poste"
                id="fonction"
                type="text"
                :class="{ 'p-invalid': !poste && submitted }"
                maxlength="40"
              />
              <h6 v-if="!poste && submitted" style="color: red">Veuillez renseigner la fonction.</h6>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="fixe">Téléphone 2</label>
              <InputText class="no-arrow" v-model="fixe" id="fixe" type="number" />
            </div>
          </div>
          <br />
          <Button v-bind:style="{ width: '25%' }" @click="back" label="Retour" class="p-mr-2 p-mb-2" />

          <Button
            v-if="role !== 'ROLE_TEMP_REJETE'"
            v-bind:style="{ width: '25%' }"
            @click="update"
            label="Enregistrer les informations"
            class="p-mr-2 p-mb-2"
          />

          <Button
            v-if="role == 'ROLE_TEMP_REJETE'"
            v-bind:style="{ width: '25%' }"
            @click="setBack"
            label="Resoumettre les informations"
            class="p-mr-2 p-mb-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../services/http.service';

export default {
  data() {
    return {
      id: '',
      nom: '',
      prenom: '',
      email: '',
      password: '',
      mobile: '',
      fixe: '',
      poste: '',
      enterpriseId: 0,
      role: '',
      token: '',
      nomerror: false,
      prenomerror: false,
      emailerror: false,
      entrepriserror: false,
      aperror: false,
      villerror: false,
      payserror: false,
      registerDialog: false,
      iscomplete: null,
      entreprise_complete: null,
      isError: false,
      submitted: false,
    };
  },
  async mounted() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      try {
        //get user's info
        const res = await http.get('/users/getProfile', { headers: { Authorization: `Bearer ${this.token}` } });
        //collect it
        this.user = res.data;
        this.id = this.user.id;
        this.nom = this.user.lastName;
        this.prenom = this.user.firstName;
        this.email = this.user.email;
        this.password = this.user.password;
        this.poste = this.user.positionDescription;
        this.enterpriseId = this.user.enterpriseId;
        this.mobile = this.user.mobile;
        this.fixe = this.user.phone;
        const lastindex = this.user.roles.length - 1;
        this.role = this.user.roles[lastindex].name;
      } catch (e) {
        console.log('ERROR DASHBOARD => ' + e);
      }
    } else {
      this.$router.push('/login');
      // console.log('No User Logged Here');
    }
  },
  methods: {
    async setBack() {
      if (
        !(this.nom === '') &&
        !(this.prenom === '') &&
        !(this.mobile === '') &&
        !(this.email === '') &&
        !(this.poste === '')
      ) {
        this.isError = false;
        /*
				{
				"email": "abdallahgueye221@gmail.com",
				"enabled": true,
				"firstName": "Gueye",
				"lastName": "Abdallah",
				"locked": false,
				"mobile": "456888",
				"positionDescription": "hjkhkhk",
				"role": "ROLE_CUSTOMER"
				}
				*/
        try {
          await http.put(
            '/users/' + this.id + '/',
            {
              email: this.email,
              firstName: this.prenom,
              lastName: this.nom,
              password: this.password,
              mobile: this.mobile,
              phone: this.fixe,
              positionDescription: this.poste,
              enabled: true,
              locked: false,
              enterpriseId: this.enterpriseId,
              isResubmittingSubscription: true,
            },
            { headers: { Authorization: `Bearer ${this.token}` } }
          );
          this.updateRole();
          this.$toast.add({
            severity: 'success',
            summary: 'Accomplie',
            detail:
              'Votre modification a bien été prise en compte. Le dossier a été retransmis à l’équipe du CNSCL qui examine les renseignements.',
            life: 7000,
          });
          //this.$router.push('/');
        } catch (e) {
          console.log('ERROR ON UPDATE TO ENTREPRISE => ' + e);
          this.isError = true;
          this.$toast.add({
            severity: 'error',
            summary: 'Echec',
            detail: "Votre modification n'a pas été prise en compte. Veuillez réessayer.",
            life: 7000,
          });
        }
      } else {
        this.isError = true;
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail: "Votre modification n'a pas été prise en compte. Veuillez réessayer.",
          life: 7000,
        });
      }
    },
    async updateRole() {
      if (
        !(this.nom === '') &&
        !(this.prenom === '') &&
        !(this.mobile === '') &&
        !(this.email === '') &&
        !(this.poste === '')
      ) {
        this.isError = false;
        const token = localStorage.getItem('token');
        const id = this.user.entreprise.abonnements[this.user.entreprise.abonnements.length - 1].id;
        // console.log('user ' + this.user.id);
        const userPaymentIsValid = this.user.entreprise.isTmpValid;
        const canUpdateRole = userPaymentIsValid;
        if (!canUpdateRole) return;
        try {
          await http.post(
            '/abonnements/' + id + '/setAbonnementValidation',
            {
              statut: 'EN_COURS',
              motifRejet: '',
              roleName: 'USER_VALIDATE',
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          //this.validDialog = false;
          this.$router.push('/');
        } catch (e) {
          console.log('error when updating => ' + e);
        }
      }
    },
    async update() {
      this.submitted = true;
      if (
        !(this.nom === '') &&
        !(this.prenom === '') &&
        !(this.mobile === '') &&
        !(this.email === '') &&
        !(this.poste === '')
      ) {
        this.isError = false;
        /*
				{
				"email": "abdallahgueye221@gmail.com",
				"enabled": true,
				"firstName": "Gueye",
				"lastName": "Abdallah",
				"locked": false,
				"mobile": "456888",
				"positionDescription": "hjkhkhk",
				"role": "ROLE_CUSTOMER"
				}
				*/
        try {
          await http.put(
            '/users/' + this.id + '/',
            {
              email: this.email,
              firstName: this.prenom,
              lastName: this.nom,
              password: this.password,
              mobile: this.mobile,
              phone: this.fixe,
              positionDescription: this.poste,
              enabled: true,
              locked: false,
              enterpriseId: this.enterpriseId,
            },
            { headers: { Authorization: `Bearer ${this.token}` } }
          );
          localStorage.setItem('nom', this.user.lastName);
          localStorage.setItem('prenom', this.user.firstName);
          localStorage.setItem('email', this.user.email);
          localStorage.setItem('mobile', this.user.mobile);
          localStorage.setItem('poste', this.user.positionDescription);
          localStorage.setItem('phone', this.user.telephone);
          this.$toast.add({
            severity: 'success',
            summary: 'Accomplie',
            detail: 'Votre modification a bien été prise en compte.',
            life: 7000,
          });
          //this.$router.push('/');
        } catch (e) {
          console.log('ERROR ON UPDATE TO ENTREPRISE => ' + e);
          this.isError = true;
          this.$toast.add({
            severity: 'error',
            summary: 'Echec',
            detail: "Votre modification n'a pas été prise en compte. Veuillez réessayer.",
            life: 7000,
          });
        }
      } else {
        this.isError = true;
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail: "Votre modification n'a pas été prise en compte. Veuillez réessayer.",
          life: 7000,
        });
      }
    },
    back() {
      this.$router.push('/');
    },
    async next() {
      this.submitted = true;
      if (
        !(this.nom === '') &&
        !(this.prenom === '') &&
        !(this.mobile === '') &&
        !(this.email === '') &&
        !(this.poste === '')
      ) {
        this.isError = false;
        try {
          await http.put(
            '/users/' + this.id + '/',
            {
              email: this.email,
              firstName: this.prenom,
              lastName: this.nom,
              password: this.password,
              mobile: this.mobile,
              phone: this.fixe,
              positionDescription: this.poste,
              enabled: true,
              locked: false,
              enterpriseId: this.enterpriseId,
              role: 'ROLE_PUBLIC',
            },
            { headers: { Authorization: `Bearer ${this.token}` } }
          );
          localStorage.setItem('nom', this.user.lastName);
          localStorage.setItem('prenom', this.user.firstName);
          localStorage.setItem('email', this.user.email);
          localStorage.setItem('mobile', this.user.mobile);
          localStorage.setItem('poste', this.user.positionDescription);
          localStorage.setItem('phone', this.user.telephone);
          this.$router.push('/menu/seat');
        } catch (e) {
          console.log('ERROR ON NEXT TO ENTREPRISE => ' + e);
          this.isError = true;
        }
      } else {
        this.isError = true;
      }
    },
  },
};
</script>

<style>
.text-red {
  color: #db3c30;
}
.no-arrow {
  -moz-appearance: textfield;
}
.no-arrow::-webkit-inner-spin-button {
  display: none;
}
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>